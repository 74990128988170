@tailwind base;
@tailwind utilities;
@tailwind components;

.orange {
  color: #f17a00;
}

.inventory-wrap {
  height: 220px;
  border: 10px solid white;
  border-radius: 5px;
  /*background-position: right 50% bottom 48px;*/
  background-size: contain;
  background-repeat: no-repeat;
}

body {
  margin: 0;
  font-family: 'Roboto';
}

.dropdown-content {
  display: none;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.dropdown .dropdown-content a:hover {
  background: white;
}

.loader {
  border-top-color: #3498db;
  -webkit-animation: spinner 1.5s linear infinite;
  animation: spinner 1.5s linear infinite;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

@-webkit-keyframes spinner {
  0 % {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spinner {
  0 % {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.photo-panel {
  margin-top: 30px;
}

.auction-start {
  line-height: 1.2em;
  padding: 10px 15px;
  border-radius: 5px;
  font-weight: bold;
  margin-bottom: 10px;
  font-size: 18px;
}

button:hover {
  opacity: 0.8;
}

.logo {
  width: 250px;
}

.floating {
  position: fixed !important;
  right: 2%;
  bottom: 1%;
}

.login-btn {
  margin-top: 30px !important;
  background: #213e97 !important;
  border-radius: 7px !important;
  width: 100% !important;
  height: 50px;
  text-transform: capitalize !important;
  margin-bottom: 40px !important;
}

.heading {
  margin-top: 20px !important;
}

*,
*::before,
*::after {
  --tw-ring-color: #ffff !important;
}

.css-ncb2v9-MuiTabs-indicator {
  background-color: #ed1d24 !important;
}

.carousel.carousel-slider .control-arrow:hover {
  background: none !important;
}

.carousel .control-dots .dot {
  background: #213e98 !important;
}

.css-46bh2p-MuiCardContent-root:last-child {
  padding-bottom: 10px !important;
}

.wrapper {
  display: flex;
  width: 100%;
  height: 35px;
}

.wrapper1 {
  background: #ed1d24;
  width: auto;
  padding: 7px 20px 0px 20px;
  color: white;
  font-size: 14px;
  border-radius: 7px 0px 0px 7px;
}

.wrapper2 {
  background: rgba(156, 163, 177, 0.13);
  width: auto;
  padding: 7px 20px 0px 40px;
  font-size: 14px;
  color: #9ca3b1;
}

.wrapper3 {
  background: rgba(156, 163, 177, 0.13);
  padding: 7px 20px 0px 20px;
  font-size: 14px;
  width: auto;
  color: #9ca3b1;
}

.wrapper > div {
  position: relative;
}

.wrapper > div:after {
  content: '';
  position: absolute;
  display: block;
  left: 99.8%;
  top: 0;
  bottom: 0;
  width: 15px;
  -webkit-clip-path: polygon(0 0, 0 100%, 100% 50%);
  clip-path: polygon(0 0, 0 100%, 100% 50%);
}

.wrapper > div.wrapper1:after {
  background-color: #ed1d24;
}
.wrapper > div.wrapper2:after {
  background-color: rgba(156, 163, 177, 0.13);
}

.wrapper > div.wrapper3:after {
  background-color: rgba(156, 163, 177, 0.13);
}

.css-46bh2p-MuiCardContent-root:last-child {
  padding-bottom: 10px;
}

.condition-card {
  background: rgba(156, 163, 177, 0.1) !important;
  border-radius: 17px !important;
}

.options {
  width: 800px !important;
  padding: 3px 0px 3px 15px !important;
  background: #fff !important;
  border: 1px solid #9ca3b1 !important;
  border-radius: 8px !important;
  margin-top: 10px;
}

.multiline {
  width: 800px !important;
  background: #fff !important;
  border-radius: 8px !important;
  margin-top: 10px;
  color: '#9CA3B1';
}

.dropzone {
  width: 138px;
  height: 138px;
  border: 1px solid #9ca3b1;
  border-radius: 8px;
}

.fileIcon {
  font-size: 60px !important;
  color: #9ca3b1 !important;
  margin: 35px 0px 0px 35px !important;
}

/* .MuiFormControl-root.MuiTextField-root.css-11eger7-MuiFormControl-root-MuiTextField-root {
  width: 100% !important;
  border-radius: 4px !important;
  background: none !important;
} */

.css-nxo287-MuiInputBase-input-MuiOutlinedInput-input {
  height: 0.4375em !important;
}

.css-1pop5uj-MuiButtonBase-root-MuiTab-root.Mui-selected {
  color: black !important;
  border-bottom: none !important;
}

.css-9a4lrq-MuiButtonBase-root-MuiTab-root.Mui-selected {
  color: black !important;
  border-bottom: none !important;
}

.css-bieu6u-MuiTabs-indicator {
  background-color: #ed1d24 !important;
}

@media screen and (min-width: 769px) and (max-width: 1400px) {

}

@media screen and (min-device-width: 481px) and (max-device-width: 768px) {
}

@media only screen and (max-device-width: 480px) {
  body {
  }
}
